import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Menu } from 'antd';
import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { DashboardIcon, GroupIcon, LightbulbIcon, SettingsIcon, TaskIcon } from '../../../../../assets/icons';
import LayoutService from '../../../../../services/layoutService';
import { isRoute } from '../../../../../utils/url-utils';
import HelpSection from './helpSection';
import LanguageSwitcher from './languageSwitcher';
import NotificationBell from './notificationBell';
import UserMenuAvatar from './userAvatar';
import UserNameCompany from './userNameCompany';

const MenuItems = ({ authData, companyData, t, location, userData }) => {
  const [menuList, setMenuList] = useState(null);

  useEffect(() => {
    getMenuItems();
  }, []);

  const getMenuItems = async () => {
    await LayoutService.getMenuItem()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setMenuList(resp.responseObject.value);
        }
      })
      .catch((error) => {});
  };

  const addStaticItemsToMenu = () => {
    let staticMenuItems = [
      {
        key: '789',
        id: 'help-section',
        className: 'userItem',
      },
    ];

    return staticMenuItems;
  };

  const generateTopMenu = (menuList) => {
    let items = menuList.map((menu) => {
      if (menu.menuItems.length > 0) {
        if (menu.menuLink === '/configurazione') return null;

        let menuObj = {
          label: menu.menuDisplayName,
          key: menu.menuID,
          children: menu.menuItems.map((menuItem) => ({
            label: <Link to={{ pathname: menuItem.menuItemLink }}>{menuItem.menuItemDisplayName}</Link>,
            key: menuItem.menuItemLink,
            disabled: menuItem.menuItemIsDisabled,
          })),
        };

        if (menu.menuItems.find((item) => item.menuItemLink === '/xmatrix')) {
          menuObj.icon = <LightbulbIcon />;
        }

        if (menu.menuItems.find((item) => item.menuItemLink === '/progettihoshinkanri')) {
          menuObj.icon = <TaskIcon />;
        }

        return menuObj;
      } else {
        return {
          children: [],
          label: (
            <Link to={{ pathname: menu.menuLink }}>
              <span className="tw-flex tw-items-center tw-gap-2">
                {menu.menuLink === '/home' && <DashboardIcon className="tw-fill-white" />}
                {menu.menuLink === '/committies' && <GroupIcon />}
                {menu.menuDisplayName}
              </span>
            </Link>
          ),
          key: menu.menuLink,
        };
      }
    });

    let staticMenuItems = addStaticItemsToMenu();
    items.push(...staticMenuItems);

    return items;
  };

  const page = `/${location.pathname.split('/')[1]}`;

  return (
    <div className="tw-flex tw-relative tw-h-full tw-justify-between tw-items-center">
      <UserNameCompany
        user={authData}
        t={t}
        companyData={companyData}
      />
      {menuList ? (
        <Menu
          className="tw-absolute lg:tw-min-w-[50%] tw-left-1/2 -tw-translate-x-1/2 tw-bottom-0 tw-bg-transparent tw-justify-center"
          mode="horizontal"
          selectedKeys={[page]}
          items={generateTopMenu(menuList)}
          overflowedIndicator={<MenuOutlined />}
          expandIcon={<DownOutlined />}></Menu>
      ) : (
        <div></div>
      )}

      <div className="tw-flex tw-items-center tw-gap-1">
        <LanguageSwitcher />
        <NotificationBell />
        <HelpSection
          companyData={companyData}
          t={t}
        />
        <Button
          href="/settings"
          className={`navbar-button ${isRoute('/settings') && 'navbar-button-active'}`}
          icon={<SettingsIcon className="tw-fill-white" />}
          type="ghost"></Button>
        <UserMenuAvatar
          userData={userData}
          t={t}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  companyData: state.companyData.companyData,
  userData: state.userData.userData,
});

export default withRouter(withTranslation()(connect(mapStateToProps)(MenuItems)));
