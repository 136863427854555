import { Avatar, Dropdown } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import '../header.scss';
import { UserDetailsRespose } from 'src/config/connectors';
import { withAuth } from 'src/config/auth/auth';

const UserMenuAvatar: FC<{
  onSignOut: () => {};
  userData: UserDetailsRespose;
}> = ({ onSignOut, userData }) => {
  const { t } = useTranslation();

  const items = [
    // {
    //   label: <Link to={{ pathname: `/progettihoshinkanri` }}>{t('headerMenu.dashboardProgetti')}</Link>,
    //   key: 1,
    // },
    { label: <a href="/myprofile">{t('headerMenu.mioProfilo')}</a>, key: 2 },
    // { label: <Link to={{ pathname: `/mycompany` }}>{t('headerMenu.miaSocieta')}</Link>, key: 3 },
    {
      label: (
        <>
          <li
            className="tw-text-red-500"
            onClick={onSignOut}>
            {t('headerMenu.esci')}
          </li>
        </>
      ),
      key: 4,
    },
  ];

  return (
    <div className="navbar-button">
      {userData && (
        <Dropdown menu={{ items }}>
          <div className="!tw-flex tw-ml-4 tw-items-center tw-gap-2 tw-cursor-pointer">
            {userData.fullName && (
              <p className="tw-block tw-leading-none tw-whitespace-nowrap tw-text-white">{userData.fullName}</p>
            )}
            {userData.profilePhoto && (
              <Avatar
                size={'default'}
                className="tw-bg-white tw-text-black"
                src={userData.profilePhoto}
              />
            )}
            {!userData.profilePhoto && (
              <Avatar
                size={'default'}
                className="tw-bg-white tw-text-black">
                {userData.userInitials}
              </Avatar>
            )}
          </div>
        </Dropdown>
      )}
    </div>
  );
};

export default withAuth(UserMenuAvatar);
